export const MenuItems3 = [
    {
      title: 'UPCOMING',
      path: '/screening',
      className: 'dropdown-link'
    },
    {
      title: 'PAST EVENTS',
      path: '/past-events',
      className: 'dropdown-link'
    },
  ];